/* html, body {
  color: #fff;
  background-color: #53463f;
}

hr {
  border-top: 1px solid #fff;
} */

body {
  margin: 0;
  padding: 0;
  font-family: monospace;
  -webkit-font-smoothing: none;
  -moz-osx-font-smoothing: none;
  background-color: #000;
  color: #fff;
  font-size: 20px;
  overflow-x: hidden;
  -webkit-touch-callout: none;
   -webkit-user-select: none;
    -khtml-user-select: none;
      -moz-user-select: none;
       -ms-user-select: none;
           user-select: none;
}

.scroll {
  z-index: 850;
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  font-size: 16px;
  font-family: Arial !important;
  -webkit-transform: scale(1.5, 1);
  -moz-transform: scale(1.5, 1);
  -o-transform: scale(1.5, 1);
  transform: scale(1.5, 1);
  pointer-events: none;
}

.legend {
  font-family: Arial !important;
  -webkit-transform: scale(1.5, 1);
  -moz-transform: scale(1.5, 1);
  -o-transform: scale(1.5, 1);
  transform: scale(1.5, 1);
  font-size: 12px;
  position: absolute;
  right: 0;
  left: 0;
  text-align: center;
  pointer-events: none;
  z-index: 850;
}

.arrows {
  position: absolute;
  left: 0;
  right: 0;
  top: 49vh;
  z-index: 950;
  text-align: center;
  pointer-events: none;
}

.arrows img {
  width: 75vw;
}

.tooltip0 {
  top: -40px;
  color: #000;
  position: absolute;
  z-index: 999;
  background-color: #fff;
  pointer-events: none;
  padding: 0px 4px;
  font-size: 14px;
  font-family: Arial !important;
  -webkit-transform: scale(1.1, 1);
  -moz-transform: scale(1.1, 1);
  -o-transform: scale(1.1, 1);
  transform: scale(1.1, 1);
}

.egrecorp, .debugger, .shooter, .valuator, .lantern {
  filter: sepia(100%);
}

.diagram {
  position: absolute;
  left: 20px;
  bottom: 60px;
  width: 100px;
}

img {
   image-rendering: pixelated;
   image-resolution: 10dpi snap;
 }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.droppable.algorithm, .droppable.input {
  width: 50%;
}

.captcha, .products, .intro, .offline, .services, .warning {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 900;
  padding: 5vw;
  max-height: 100vh;
  overflow-x: hidden;
  overflow-y: hidden;
  height: 100vh;
}

.services {
  overflow-y: scroll;
}

.warning {
  z-index: 999;
  background-color: rgba(0,0,0,0.8) !important;
}

.intro {
  pointer-events: none;
}

.services img {
  width: 30vw;
}

.home {
  padding: 10vw;
  max-height: 100vh;
  overflow: hidden;
  overflow-y: scroll;
  height: 100vh;
}

.gatekeeper {
  padding: 10vw;
  max-height: 100vh;
  overflow-y: scroll;
  height: 100vh;
}

.echo {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  image-rendering: crisp-edges;
  image-rendering: -moz-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: optimize-contrast;
  -ms-interpolation-mode: nearest-neighbor;
  width: 100vw;
	height: 100vh;
}

.agent {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 900;
  width: 20%;
  height: auto;
}

.threejs {
  image-rendering: crisp-edges;
  image-rendering: -moz-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: optimize-contrast;
  -ms-interpolation-mode: nearest-neighbor;
	position: absolute;
}

.interface {
  width: 100%;
  min-height: 100vh;
  filter: invert(100%);
  z-index: 0;
}

.manifesto {
  color: #000;
  top: 10vw;
  left: 20vw;
  width: 60vw;
  background-size: 100%;
  padding: 20px 11%;
  position: absolute;
  -webkit-filter: drop-shadow(0px 0px 20px rgba(255,255,255,0.5));
}
.manifesto .text {
  width: 100px !important;
  padding-bottom: 100px !important;
}

.manifesto img {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

.manifesto i {
  color: #ff0000;
}

.alertimage {
  border: 1px solid #fff;
  height: 50px;
  float: left;
  margin-right: 10px;
}

.alertheader {
  color: #ff00ff;
}

.plugin {
  pointer-events: auto;
  position: relative;
  display: inline-block;
  margin: 10px;
  vertical-align: top;
  background-color: #333;
  border-radius: 5px;
  padding: 20px;
  border-top: 1px solid #fff;
  color: #fff;
  vertical-align: top;
  position: relative;
}

.plugin .description {
  border: 1px solid #fff;
  font-size: 20px !important;
}

.plugin>div {
  vertical-align: top;
  display: inline-block;
}

.plugin a {
  position: relative;
  background-color: #000;
  color: #fff;
  padding: 5px;
  border-radius: 5px;
  width: 100%;
  -webkit-transition: all 0.2s ease-in;
     -moz-transition: all 0.2s ease-in;
       -o-transition: all 0.2s ease-in;
          transition: all 0.2s ease-in;
}

.plugin a:hover {
  background-color: #fff;
  color: #000 !important;
}

.pluginlogo {
  width: 50px !important;
  position: absolute;
  right: 0;
  bottom: 15px;
  border: none !important;
}

.plugin img {
  margin-right: 20px;
  width: 200px;
  border-radius: 5px;
  border-bottom: 1px solid #fff;
}

.product {
  position: relative;
  display: inline-block;
  margin: 10px;
  width: 30%;
  vertical-align: top;
}

.product .description {
  margin-top: 10px;
}

.product img {
  width: 100%;
}

.sponsor {
  display: inline-block;
  text-align: center;
  height: 30vw;
  margin-bottom: 20vw;
}
.sponsor>img {
  margin: 5px;
  width: 30vw;
}

.selectable:hover {
  cursor: pointer;
  border-bottom: 1px solid #fff;
}

.grid {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.header {
  position: absolute;
  z-index: 999;
  right: 0;
  width: 100vw;
  background-color: #000;
}

.nav-header {
  position: absolute;
  z-index: 999;
  right: 0;
  width: 100vw;
}

a {
  border-bottom: 1px dotted;
  color: #fff;
}

a:hover {
  cursor: pointer;
  color: #ffed00 !important;
  text-decoration: none;
}

.shooter, .valuator, .lantern {
  position: fixed;
  bottom: 0;
  z-index: 950;
  width: 100vw;
}
.shooter>.image {
  position: absolute;
  right: 5%;
  bottom: 0;
  width: 250px;
}
.valuator>.image {
  position: absolute;
  right: 5%;
  bottom: 0;
  width: 200px;
}
.lantern>.image {
  position: absolute;
  right: 5%;
  bottom: 0;
  width: 200px;
}
.shooter>.image img, .valuator>.image img, .lantern>.image img {
  width: 100%;
}
.bar {
  z-index: 999;
  width: 100%;
  position: absolute;
  right: 50px;
  -webkit-transition: all 0.2s ease-in-out;
     -moz-transition: all 0.2s ease-in-out;
       -o-transition: all 0.2s ease-in-out;
          transition: all 0.2s ease-in-out;
}
.hud {
  position: absolute;
  bottom: 90vh;
  left: 40vw;
  width: 20vw;
  text-align: center;
  color: #000;
  z-index: 999;
  background-color: #e7daa5;
  pointer-events: none;
  padding: 0px 4px;
}

.debugger {
  pointer-events: auto;
  position: fixed;
  bottom: 0;
  z-index: 999;
  width: 100vw;
}

.voter {
  position: fixed;
  left: 10px;
  top: 40px;
  z-index: 999;
  width: 400px;
  color: #e7daa5;
}

.coder {
  pointer-events: default;
  text-transform: uppercase;
  cursor: default;
  position: fixed;
  top: 30vh;
  bottom: 30vh;
  left: 20vw;
  right: 20vw;
  background:
    url('data:image/svg+xml;utf8,<svg viewBox="0 0 3 3" xmlns="http://www.w3.org/2000/svg"><rect width="1" height="1" /></svg>') 0   0  /3px 3px,
    url('data:image/svg+xml;utf8,<svg viewBox="0 0 3 3" xmlns="http://www.w3.org/2000/svg"><rect width="1" height="1" /></svg>') 1px 1px/3px 3px,
    url('data:image/svg+xml;utf8,<svg viewBox="0 0 3 3" xmlns="http://www.w3.org/2000/svg"><rect width="1" height="1" /></svg>') 2px 2px/3px 3px,
    url(https://i.picsum.photos/id/102/800/800.jpg) center/cover;
  color: #e7daa5;
  border: 1px solid #e7daa5;
  z-index: 995;
}

.logs {
  z-index: 997;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  pointer-events: none;
  color: #e7daa5;
}

.plot {
  position: absolute;
  bottom: 20px;
  right: 20px;
  width: 200px;
  height: 100px;
  padding: 10px;
  background:
    url('data:image/svg+xml;utf8,<svg viewBox="0 0 3 3" xmlns="http://www.w3.org/2000/svg"><rect width="1" height="1" /></svg>') 0   0  /3px 3px,
    url('data:image/svg+xml;utf8,<svg viewBox="0 0 3 3" xmlns="http://www.w3.org/2000/svg"><rect width="1" height="1" /></svg>') 1px 1px/3px 3px,
    url('data:image/svg+xml;utf8,<svg viewBox="0 0 3 3" xmlns="http://www.w3.org/2000/svg"><rect width="1" height="1" /></svg>') 2px 2px/3px 3px,
    url(https://i.picsum.photos/id/102/800/800.jpg) center/cover;
  color: #e7daa5;
  border: 1px solid #e7daa5;
}

.point {
  position: absolute;
}

.coder.special {
  top: 0 !important;
  bottom: 0 !important;
  right: 0 !important;
  left: 0 !important;
  height: 300px;
  position: relative !important;
}

.coder.special .brick {
  cursor: pointer;
}

.closecoder {
  position: absolute;
  top: 100px;
  right: 100px;
  cursor: alias;
}

.coderbg {
  cursor: alias;
  z-index: 994;
  background: rgba(0,0,0,0);
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
}

.coder>div {
  overflow-y: hidden;
  border: 1px dotted #e7daa5;
  height: 100%;
  display: inline-block;
  vertical-align: top;
  padding: 3px 10px;
  margin: 0;
}

.tooltipa, .tooltipb, .tooltipc, .ammotip {
  top: -40px;
  color: #000;
  position: absolute;
  z-index: 999;
  background-color: #e7daa5;
  pointer-events: none;
  padding: 0px 4px;
}

.brick {
  user-select: none;
  width: 50px;
  height: 50px;
  border: 1px solid #e7daa5;
  display: inline-block;
  text-align: center;
  z-index: 999;
  background: #000 !important;
  filter: contrast(250%);
  overflow: hidden;
  transform: translateZ(0);
  vertical-align: middle;
}

.product {
  background: #000 !important;
  filter: contrast(200%) brightness(500%);
  overflow: hidden;
  transform: translateZ(0);
}

.brick::after, .product::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-blend-mode: multiply;
  background: radial-gradient(2px 2px, #fff, #000);
  background-size: 2px 2px;
  background-position: 0 -3px, -2px 0, 2px 0;
  mix-blend-mode: multiply;
}

.brick>img {
  filter: sepia(100%) brightness(300%);
  max-width: 100%;
  max-height: 100%;
  vertical-align: middle;
}

.selector {
  color: #22e611 !important;
  cursor: pointer;
}

.debugger>.image {
  position: absolute;
  left: 20px;
  bottom: 0;
  width: 200px;
}
.debugger>.image img {
  width: 100%;
}

.debugger>.textbox {
  position: absolute;
  pointer-events: none;
  left: 220px;
  right: 240px;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  /*border: double;*/
  padding: 10px;
}

.debugger>.avatar, .diagram {
  position: absolute;
  pointer-events: none;
  bottom: 40px;
  right: 90px;
  z-index: 999;
  width: 100px;
  height: 100px;
  border: 1px solid #c4b15f;
  background: #000;
  filter: contrast(250%);
  overflow: hidden;
  transform: translateZ(0);
}

.avatar::after, .diagram::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-blend-mode: multiply;
    background: radial-gradient(2px 2px, #fff, #000);
    background-size: 2px 2px;
    background-position: 0 -3px, -2px 0, 2px 0;
    mix-blend-mode: multiply;
}

.debugger>.avatar img, .diagram img {
  filter: sepia(100%);
  pointer-events: auto !important;
  width: 100%;
  height: auto;
  /* -webkit-filter: drop-shadow(0px 0px 20px rgba(255,255,255,0.5)); */
}

.anon {
  position: absolute;
  width: 100%;
  text-align: center;
  top: 37%;
}

.debugger>.alert {
  position: absolute;
  right: 0;
  bottom: 0;
  padding: 0;
  margin: 0;
  text-align: left;
  width: 240px;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 0;
}

.navbar {
  background: rgba(0,0,0,0.5);
  height: 20px !important;
  box-shadow: none;
}

.navbar-nav {
  margin: 0 !important;
}

.btn-margin {
  color: #fff;
  border: none;
  border-radius: 0;
  background: none !important;
  font-size: 12px !important;
}

.d-md-none {
  visibility: hidden !important;
}

.nav-link {
  color: #fff !important;
  background-color: none !important;
  border-width: 1px !important;
  cursor: pointer;
  padding: 0 !important;
  margin: 0 !important;
  display: inline-block !important;
}

.home-link, .nav-item {
  color: #fff !important;
  background-color: none !important;
  border-width: 1px !important;
  border-color: #ffed00 !important;
  cursor: pointer;
  padding: 0 10px 0 10px !important;
  margin: 0 !important;
  display: block !important;
}

.nav-link:hover, .nav-item:hover {
  color: #ffed00 !important;
  text-decoration: none;
}

.next-steps .fa-link {
  margin-right: 5px;
}

/* Fix for use only flexbox in content area */
.next-steps .row {
  margin-bottom: 0;
}

.next-steps .col-md-5 {
  margin-bottom: 3rem;
}

@media (max-width: 768px) {
  .next-steps .col-md-5 {
    margin-bottom: 0;
  }
}

.spinner {
  position: absolute;
  display: flex;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background-color: white;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.forumpost {
  width: 100%;
  margin-bottom: 10px;
  vertical-align: top;
}
.forumpost>div {
  display: inline-block;
}
.forumpost>.message {
  width: 80%;
  vertical-align: top;
}
.forumpost img {
  width: 70px;
}

.forumpost>.profileinfo {
  width: 20%;
  text-align: center;
}

.result-block-container .result-block {
  opacity: 1;
}

.lang {
  border: none;
  padding: 0 4px !important;
}
.lang.active, .exit {
  border: none;
  padding: 0 4px !important;
  color: #000 !important;
  background-color: #e7daa5;
}
